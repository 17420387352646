import "./styles/app.css";

function App() {
    return (
        <div className="App">
            <h1>
                Jewellery by <span>Amatu</span>
            </h1>

            <h2>Coming soon...</h2>
        </div>
    );
}

export default App;
